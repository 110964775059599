var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"authentication-bg min-vh-100"},[_c('div',{staticClass:"bg-overlay bg-white"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex flex-column min-vh-100 px-3 pt-4"},[_c('div',{staticClass:"row justify-content-center my-auto"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-4"},[_c('div',{staticClass:"text-center py-5"},[_c('div',{staticClass:"mb-4 mb-md-5"},[_c('router-link',{staticClass:"d-block auth-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"auth-logo-dark",attrs:{"src":require("@/assets/images/logo-dark.png"),"alt":"","height":"22"}}),_c('img',{staticClass:"auth-logo-light",attrs:{"src":require("@/assets/images/logo-light.png"),"alt":"","height":"22"}})])],1),_vm._m(0),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.registerSuccess),callback:function ($$v) {_vm.registerSuccess=$$v},expression:"registerSuccess"}},[_vm._v("Registration successfull.")]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":"","show":_vm.notificationAutoCloseDuration},on:{"dismissed":_vm.clear},model:{value:(_vm.isRegisterError),callback:function ($$v) {_vm.isRegisterError=$$v},expression:"isRegisterError"}},[_vm._v(_vm._s(_vm.regError))]),(_vm.notification.message)?_c('div',{class:'alert alert-' + _vm.notification.type},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn.apply(null, arguments)}}},[_c('div',{staticClass:"form-floating form-floating-custom mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                  },attrs:{"type":"email","id":"input-email","placeholder":"Enter Email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('label',{attrs:{"for":"input-email"}},[_vm._v("Email")]),_vm._m(1),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-floating form-floating-custom mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.user.username.$error,
                  },attrs:{"type":"text","id":"input-username","placeholder":"Enter User Name"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "username", $event.target.value)}}}),_c('label',{attrs:{"for":"input-username"}},[_vm._v("Username")]),_vm._m(2),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username is required. ")]):_vm._e()]),_c('div',{staticClass:"form-floating form-floating-custom mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                  },attrs:{"type":"password","id":"floatingPassword","placeholder":"Enter Password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('label',{attrs:{"for":"floatingPassword"}},[_vm._v("Password")]),_vm._m(3),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()]),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Already have an account ? "),_c('router-link',{staticClass:"fw-medium text-decoration-underline",attrs:{"to":"/auth/signin-basic"}},[_vm._v(" Signin ")])],1)])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"text-center text-muted p-4"},[_c('p',{staticClass:"mb-0"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Dashonic. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Pichforest ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('h5',{},[_vm._v("Register Account")]),_c('p',[_vm._v("Get your free Dashonic account now.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-floating-icon"},[_c('i',{staticClass:"uil uil-envelope-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-floating-icon"},[_c('i',{staticClass:"uil uil-users-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-floating-icon"},[_c('i',{staticClass:"uil uil-padlock"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-start"},[_c('p',[_vm._v(" By registering you agree to the Dashonic "),_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"#"}},[_vm._v("Terms of Use")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-info w-100",attrs:{"type":"submit"}},[_vm._v(" Register ")])])
}]

export { render, staticRenderFns }